import EM from "./ErrorMessage";

export default function parse(e) {
  e.preventDefault();
  var formData = new FormData(e.target);
  let values = {};
  for (var pair of formData.entries()) {
    values = { ...values, ...{ [pair[0]]: pair[1] } };
  }
  return values;
}

export const validate = (values, cb, array) => {
  for (var i in values) {
    let c = values[i];
    if (array && array.indexOf(i) > -1) {
      continue;
    }
    if (!c || c.trim().length < 3) {
      if (i.includes("_")) {
        i = i.replace("_", " ");
      }

      EM.normal(i + " ist nicht gültig");
      cb(false);
      return false;
    }
  }
  cb(true);
};
