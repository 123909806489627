import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../containers/sidebar";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../../containers/navbar";
import { authHeader } from "../../functions/users";
import { useHistory } from "react-router-dom";
import Home from "../Home";
import { UserContext } from "../../contexts/UserContext";
import FormParser, { validate } from "../../util/FormParser";
import { NotificationContainer } from "react-notifications";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import { AuthContext } from "../../contexts/AuthContext";
function CreateNews() {
  let history = useHistory();
  const { user, restaurant, loadRestaurant, changeRestaurant } =
    useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState(null);

  const onChangeContent = (e) => {
    let content = e.target.value;
    changeRestaurant({ content });
  };
  const handleLineBreak = (e) => {
    e.preventDefault(e);
    changeRestaurant({ content: restaurant.content + "<br/>" });
  };
  const handleParagraph = (e) => {
    e.preventDefault(e);
    changeRestaurant({
      content:
        restaurant.content + "<p>Bitte schreiben Sie Ihren Absatz hier</p>",
    });
  };
  const handleBold = (e) => {
    e.preventDefault(e);
    changeRestaurant({
      content:
        restaurant.content +
        "<strong>Bitte Schreiben Sie hier, um den Text fett zu machen</strong>",
    });
  };

  useEffect(() => {
    loadRestaurant();
  }, []);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    let data = {
      title: restaurant.title,
      description: restaurant.description,
      content: restaurant.content,
      picture: restaurant.picture,
    };

    validate(
      data,
      async (d) => {
        if (d) {
          if (selectedFile == null) {
            Call({
              path: "home/restaurant/" + restaurant._id,
              method: "patch",
              data,
            }).then((d) => {
              AM.success("Edited restaurant");
              loadRestaurant();
            });
          } else {
            let formData = new FormData();
            formData.append("file", selectedFile);
            for (let v in data) {
              formData.append(v, data[v]);
            }
            Call({
              path: "home/restaurant/multipart/" + restaurant._id,
              method: "patch",
              data: formData,
            }).then((d) => {
              setSelectedFile(null);
              AM.success("Bearbeitet");
              loadRestaurant();
            });
          }
        }
      },
      []
    );
  };
  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="container float-right mx-auto">
        {restaurant ? (
          <form onSubmit={onHandleSubmit}>
            <div className="bg-white">
              <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex space-x-4 items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center">
                    {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                  </div>

                  <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <a
                      href="/news-management"
                      className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md  text-sm font-medium text-black bg-transparent hover:bg-gray-200"
                    >
                      Abbrechen
                    </a>
                  </div>
                  <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <button
                      type="submit"
                      className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                    >
                      Fertig
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
              <div className="max-w-xl w-full space-y-12">
                <div>
                  <div className="md:grid md:grid-cols-1 ">
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <h2 className="mt-2 text-left text-1xl font-bold text-green-800">
                            Neues Vereinsheim{" "}
                          </h2>
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Titel
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  required
                                  size="60"
                                  value={restaurant.title}
                                  onChange={(e) =>
                                    changeRestaurant({ title: e.target.value })
                                  }
                                  maxLength="60"
                                  name="title"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder="Titel"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Beschreibung
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <textarea
                                  type="text"
                                  required
                                  size="800"
                                  maxLength="800"
                                  value={restaurant.description}
                                  onChange={(e) =>
                                    changeRestaurant({
                                      description: e.target.value,
                                    })
                                  }
                                  name="description"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder="Beschreibung"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Bild
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <img
                                src={restaurant.picture}
                                alt=""
                                style={{
                                  objectFit: "cover",
                                  height: "200px",
                                  width: "100%",
                                }}
                              />
                              <div className="mt-1 flex rounded-md">
                                <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                  <div className="absolute">
                                    <div className="flex flex-col items-center ">
                                      {" "}
                                      <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                      <span className="block text-gray-400 font-normal">
                                        Hängen Sie hier Ihre Dateien an
                                      </span>{" "}
                                      <span className="block text-gray-400 font-normal">
                                        oder
                                      </span>{" "}
                                      <span className="block text-blue-400 font-normal">
                                        Dateien durchsuchen
                                      </span>{" "}
                                    </div>
                                  </div>{" "}
                                  <input
                                    type="file"
                                    className="h-full w-full opacity-0"
                                    name=""
                                    accept="image/*"
                                    multiple
                                    onChange={(e) => {
                                      setSelectedFile(e.target.files[0]);
                                    }}
                                  />
                                </div>
                                <div className="flex justify-between items-center text-gray-400">
                                  {" "}
                                  <span>
                                    Akzeptierte Bilder nur jpeg/png
                                  </span>{" "}
                                  <span className="flex items-center ">
                                    <i className="fa fa-lock mr-1"></i> secure
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Inhalt
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="flex space-x-4">
                                <button
                                  onClick={handleLineBreak}
                                  className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                                >
                                  Linie brechen
                                </button>
                                <button
                                  onClick={handleParagraph}
                                  className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                                >
                                  Absatz
                                </button>
                                <button
                                  onClick={handleBold}
                                  className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                                >
                                  Fett
                                </button>
                              </div>

                              <div className="mt-1 flex rounded-md shadow-sm">
                                <textarea
                                  onChange={onChangeContent}
                                  value={restaurant.content}
                                  rows="20"
                                  className="resize-y rounded-md focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder="Bitte geben Sie hier Ihren Nachrichteninhalt ein"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CreateNews;
