import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { LockClosedIcon } from "@heroicons/react/solid";
import { NotificationContainer } from "react-notifications";
import { AuthContext } from "../../contexts/AuthContext";
import FormParser from "../../util/FormParser";
import { NotificationManager } from "react-notifications";

const Login = () => {
  console.log("PUBLIC URL", process.env.PUBLIC_URL);

  const { handleLogin } = useContext(AuthContext);
  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const loginSubmit = (e) => {
    let values = FormParser(e);
    if (!values.email) {
      NotificationManager.error("Provide valid email", null, 3000);
      return;
    }

    if (!values.password || values.password?.length < 6) {
      NotificationManager.error(
        "Password must be at least 6 digits",
        null,
        3000
      );
      return;
    }

    setLoading(true);

    handleLogin(values)
      .then((d) => {
        if (d) {
          window.localStorage.setItem("token", d.token);
          history.push("/about-us");
        } else {
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://beyondxlabs.com/wp-content/uploads/2021/01/Logo.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Bei Ihrem Konto anmelden
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            action="#"
            method="POST"
            onSubmit={loginSubmit}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  E-Mail Adresse
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="E-Mail Adresse"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Passwort
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <Link
                  to="#"
                  className="font-medium text-green-600 hover:text-green-500"
                >
                  Haben Sie Ihr Passwort vergessen?
                </Link>
              </div>
            </div>

            <div>
              <button className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-green-500 group-hover:text-green-400"
                    aria-hidden="true"
                  />
                </span>
                Anmelden
              </button>
            </div>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;
