import { Fragment, useRef, useState } from "react";
import { useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HiLockOpen } from "react-icons/hi";

const CreatePassword = (props) => {
  let history = useHistory();
  const match = useRouteMatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const token = match.params.token;

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const onChangeRepeatPassword = (e) => {
    if (password === e.target.value) {
      setError(false);
    } else {
      setError(true);
    }
  };
  const onHandleCreatePassword = async (e) => {
    e.preventDefault();
    if (error) {
      return alert("Bitte versuchen Sie es erneut ");
    }
    let data = {
      email: email,
      password: password,
    };
    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/auth/verifyPassword/${token}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await response.json();
    if (result.errorCode === 100) {
      return alert("Invalid Email");
    }
    if (response.ok && result) {
      alert("Bitte Anmelden");
      window.location.replace("/");
    }
  };

  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
              Passwort für Ihr Konto erstellen
            </h2>
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />

            <div className="grid grid-cols-6 gap-0 ">
              <div className="col-span-3 mt-3 sm:col-span-3 justify-center">
                {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                <div className=" text-sm font-medium text-gray-700">Email</div>
                {/* </div> */}
              </div>
              <div className="col-span-3 sm:col-span-3">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="email"
                    className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    placeholder="Email"
                    value={email}
                    onChange={onChangeEmail}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-0 ">
              <div className="col-span-3 mt-3 sm:col-span-3 justify-center">
                {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                <div className=" text-sm font-medium text-gray-700">
                  Passwort
                </div>
                {/* </div> */}
              </div>
              <div className="col-span-3 sm:col-span-3">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="password"
                    className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    placeholder="Passwort"
                    value={password}
                    onChange={onChangePassword}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 gap-0 ">
              <div className="col-span-3 mt-3 sm:col-span-3 justify-center">
                {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                <div className=" text-sm font-medium text-gray-700">
                  Passwort Wiederholen
                </div>
                {/* </div> */}
              </div>
              <div className="col-span-3 sm:col-span-3">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="password"
                    className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                    placeholder="Passwort Wiederholen"
                    // value={repeatPassword}
                    onChange={onChangeRepeatPassword}
                  />
                </div>
              </div>
            </div>
            {error ? (
              <span className="text-right text-xs text-red-900">
                *Passwort stimmt nicht überein
              </span>
            ) : null}
            <br />
            <div>
              <button
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={onHandleCreatePassword}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <HiLockOpen
                    className="h-5 w-5 text-green-500 group-hover:text-green-400"
                    aria-hidden="true"
                  />
                </span>
                Weiter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatePassword;
