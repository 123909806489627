import React, { useEffect, useState } from "react";
import Sidebar from "../../containers/sidebar";
import { useRouteMatch } from "react-router";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../../containers/navbar";
import { authHeader } from "../../functions/users";
import { useHistory } from "react-router-dom";
import Home from "../Home";
import { validate } from "../../util/FormParser";
import { NotificationContainer } from "react-notifications";
import Switch from "react-switch";

const EditAdmin = (props) => {
  let history = useHistory();
  const match = useRouteMatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dob, setDob] = useState("");
  const [adminNo, setAdminNo] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  // const pro = props.match.params;
  const [isPublic, setIsPublic] = useState(false);

  const handleToggle = (e) => {
    if (isPublic) {
      setIsPublic(false);
    } else {
      setIsPublic(true);
    }
  };

  const id = match.params.id;

  const updateData = async (v) => {
    let data = {
      first_name: firstName,
      last_name: lastName,
      start_date: startDate,
      end_date: endDate,
      dob,
      admin_number: adminNo,
      address,
      phone_number: phoneNumber,
      isActive: isPublic,
    };

    validate(
      data,
      async (d) => {
        if (d) {
          try {
            const { Authorization } = authHeader();
            if (Authorization === "") {
              return alert("Token Not Found. Please ReLogin");
            }
            let response = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/auth/update/${id}`,
              {
                method: "PATCH",
                mode: "cors",
                headers: {
                  authorization:
                    "Bearer " + window.localStorage.getItem("token") || "",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );

            // // let result = await response.json();
            // if (response.status === 401) {
            //   alert("Only Super Admin can add admin");
            // }
            if (response.ok) {
              history.push("/admin-management");
            }
          } catch (error) {
            console.log(error);
          }
        }
      },
      ["admin_number", "isActive"]
    );
  };
  async function fetchAdminData() {
    const { Authorization } = authHeader();
    try {
      if (Authorization === "") {
        return alert("Token Not Found. Please ReLogin");
      }
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/auth/admins/${id}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            authorization:
              "Bearer " + window.localStorage.getItem("token") || "",
            "Content-Type": "application/json",
          },
        }
      );
      let result = await response.json();
      setFirstName(result.first_name);
      setLastName(result.last_name);
      setStartDate(result.start_date);
      setEndDate(result.end_date);
      setDob(result.dob);
      setAddress(result.address);
      setPhoneNumber(result.phone_number);
      setEmail(result.email);
      setIsPublic(result.isActive);
      setAdminNo(result.admin_number);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchAdminData();
  }, []);

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const onChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };
  const onChangeDob = (e) => {
    setDob(e.target.value);
  };
  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    updateData();
  };

  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="container float-right mx-auto">
        <div className="bg-white">
          <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex space-x-4 items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
              </div>

              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  href="/admin-management"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md  text-sm font-medium text-black bg-transparent hover:bg-gray-200"
                >
                  Abbrechen
                </a>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <button
                  onClick={onHandleSubmit}
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                >
                  Aktualisierung
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
          <div className="max-w-xl w-full space-y-12">
            <div>
              <div className="md:grid md:grid-cols-1 ">
                <div className="mt-5 md:mt-0 md:col-span-4">
                  <form>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <h2 className="mt-2 text-left text-1xl font-bold text-green-800">
                          Verwaltung bearbeiten
                        </h2>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Vorname
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXX"
                                value={firstName}
                                onChange={onChangeFirstName}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Nachname
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXX"
                                value={lastName}
                                onChange={onChangeLastName}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Startdatum
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="date"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                value={startDate}
                                onChange={onChangeStartDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Enddatum
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="date"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXX"
                                value={endDate}
                                onChange={onChangeEndDate}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Geburtstag
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="date"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXX"
                                value={dob}
                                onChange={onChangeDob}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Adresse
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXXXXXX"
                                value={address}
                                onChange={onChangeAddress}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700"></div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXXXXXX"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Handy Nummer
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXXXXXXXX"
                                value={phoneNumber}
                                onChange={onChangePhoneNumber}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              E-mail
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="email"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="XXXXX@XXX.com"
                                value={email}
                                onChange={onChangeEmail}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Ist öffentlich
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) => handleToggle(e)}
                                checked={isPublic}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
