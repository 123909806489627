import { useCallback, useEffect, useState, useContext } from "react";
import Call from "../../util/Call";
import Sidebar from "../../containers/sidebar";
import Navbar from "../../containers/navbar";
import FormParser, { validate } from "../../util/FormParser";
import { useHistory } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router";
import Switch from "react-switch";
import AM from "../../util/AlertMessage";
import Modal from "./file.modal";
import AddModal from "./file.add.modal";
import {
  HiDotsHorizontal,
  HiExclamation,
  HiOutlineTrash,
  HiDuplicate,
  HiEye,
  HiPencil,
} from "react-icons/hi";
import { NotificationContainer } from "react-notifications";
export default function File(props) {
  const { user } = useContext(AuthContext);
  const match = useRouteMatch();

  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const id = match.params.id;
  let list = useCallback(() => {
    Call({
      path: "home/gallery/" + id + "/files",
      method: "get",
    }).then((d) => {
      setFiles(d);
    });
  }, [id]);

  useEffect(() => {
    list();
  }, [list]);

  let handleRemove = (id) => {
    if (window.confirm("Are you sure")) {
      Call({ path: "home/gallery/file/" + id, method: "delete" })
        .then((d) => {
          list();
        })
        .catch((err) => AM.error("try again"));
    }
  };

  return (
    <div className="bg-white">
      <>
        <NotificationContainer />
        <Sidebar />
        <Navbar />
        <AddModal
          open={showAddModal}
          id={id}
          handleClose={() => {
            setShowAddModal(false);
          }}
          handleOk={() => {
            setShowAddModal(false);
            list();
          }}
        />
        {file ? (
          <Modal
            name={file.title}
            isPublic={file.isPublic}
            open={showModal}
            id={file._id}
            handleClose={() => {
              setShowModal(false);
              setFile(null);
            }}
            handleOk={() => {
              setShowModal(false);
              list();
              setFile(null);
            }}
          />
        ) : (
          ""
        )}

        {user ? (
          <>
            <div className="max-w-3xl mx-auto px-4 sm:px-6 xl:max-w-5xl xl:px-0  flex">
              <main className="w-full max-w-8xl mx-auto font-lexend">
                <div className="bg-white">
                  <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center">
                        {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                      </div>
                      {user.role === "Superadministrator" ? (
                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <button
                            onClick={(e) => {
                              setShowAddModal(true);
                            }}
                            className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                          >
                            Bilder hinzufügen
                          </button>
                        </div>
                      ) : (
                        <div className="text-sm">
                          ‘Edit’, ’Delete’, ’New Admin’ functions are only
                          available for ’Super’ Admin. &nbsp;
                          <a href="/" className="text-green-500">
                            <u>See More</u>
                          </a>
                          <HiExclamation className="w-5 h-5 text-green-500" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex flex-col">
                  <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="pl-60	 align-middle block sm:pl-60 lg:pl-60">
                      <div className="shadow border-b border-gray-200">
                        <div className="max-w-2xl mx-auto px-4 py-6  sm:px-6 lg:max-w-7xl lg:px-8">
                          <h2 className="sr-only">Albums</h2>
                          <div></div>
                          <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 ">
                            {files?.map((file, i) => (
                              <div className="card flex flex-col justify-center p-2 bg-white rounded-lg shadow-2xl">
                                <div className="prod-title">{file.title}</div>
                                <div className="prod-img">
                                  <img
                                    src={file.url}
                                    alt={file.title}
                                    style={{
                                      width: "100%",
                                      height: "200px",
                                      objectFit: "cover",
                                    }}
                                    className="w-full object-cover object-center"
                                  />
                                </div>
                                <br />
                                <div className="flex py-2 px-5 flex-col md:flex-row justify-between items-center ">
                                  <button
                                    onClick={(e) => handleRemove(file._id)}
                                    className="px-6 mr-2 py-2 transition ease-in duration-200 uppercase rounded-full text-red-900 hover:bg-red-800 hover:text-white border-2 border-red-900 focus:outline-none"
                                  >
                                    Löschen
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      setFile(file);
                                      setShowModal(true);
                                    }}
                                    className="px-6 py-2 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-white border-2 border-blue-900 focus:outline-none text-blue-900"
                                  >
                                    Bearbeiten
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div class="flex justify-center"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    </div>
  );
}
