import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../../containers/sidebar";
import { useRouteMatch } from "react-router";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../../containers/navbar";
import { authHeader } from "../../functions/users";
import { useHistory } from "react-router-dom";
import Home from "../Home";
import { validate } from "../../util/FormParser";
import { NotificationContainer } from "react-notifications";
import call from "../../util/Call";
import Switch from "react-switch";
import AM from "../../util/AlertMessage";
import ReactTagInput from "@pathofdev/react-tag-input";
import { AuthContext } from "../../contexts/AuthContext";

const Edit = (props) => {
  const { user } = useContext(AuthContext);

  let history = useHistory();
  const match = useRouteMatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [isPublic, setIsPublic] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [document, setDocument] = useState("");
  const [features, setFeatures] = React.useState([]);

  const handleToggle = (e) => {
    if (isPublic) {
      setIsPublic(false);
    } else {
      setIsPublic(true);
    }
  };
  // const pro = props.match.params;
  const id = match.params.id;

  const updateData = async (v) => {
    let data = {
      title,
      description,
      price,
      isPublic,
      document,
      features,
    };

    let p = parseInt(price);
    if (!p) {
      AM.error("Preis ist nicht gültig");
      return;
    }
    validate(
      data,
      async (d) => {
        if (d) {
          if (selectedFile == null) {
            call({
              path: "home/membership/" + id,
              method: "patch",
              data,
            }).then((d) => {
              AM.success("Bearbeitet");
              fetchAdminData();
            });
          } else {
            let formData = new FormData();
            formData.append("file", selectedFile);
            for (let v in data) {
              formData.append(v, data[v]);
            }
            call({
              path: "home/membership/multipart/" + id,
              method: "patch",
              data: formData,
            }).then((d) => {
              setSelectedFile(null);
              AM.success("Bearbeitet");
              fetchAdminData();
            });
          }
        }
      },
      ["isPublic", "price", "features"]
    );
  };
  async function fetchAdminData() {
    call({ path: "home/membership/" + id, method: "GET" }).then((d) => {
      console.log(d);
      setTitle(d.title);
      setDescription(d.description);
      setPrice(d.price);
      setDocument(d.document);
      setIsPublic(d.isPublic);
      setFeatures(d.features);
    });
  }
  useEffect(() => {
    fetchAdminData();
  }, [id]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    updateData();
  };

  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="container float-right mx-auto">
        <form onSubmit={onHandleSubmit}>
          <div className="bg-white">
            <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex space-x-4 items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                </div>

                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <a
                    href="/membership-management"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md  text-sm font-medium text-black bg-transparent hover:bg-gray-200"
                  >
                    Abbrechen
                  </a>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                  >
                    Fertig
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
            <div className="max-w-xl w-full space-y-12">
              <div>
                <div className="md:grid md:grid-cols-1 ">
                  <div className="mt-5 md:mt-0 md:col-span-4">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <h2 className="mt-2 text-left text-1xl font-bold text-green-800">
                          Bearbeiten Mitgliedschaft
                        </h2>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Mitgliedschaft Titel
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="60"
                                maxLength="60"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Titel"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Beschreibung
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="80"
                                maxLength="80"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Beschreibung"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Preis
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="number"
                                required
                                name="price"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Preise"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Bild
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <embed
                              alt="news image"
                              src={document}
                              style={{
                                objectFit: "cover",
                                height: "200px",
                                width: "100%",
                              }}
                            />
                            <div className="mt-1 flex rounded-md">
                              <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                <div className="absolute">
                                  <div className="flex flex-col items-center ">
                                    {" "}
                                    <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      Hängen Sie hier Ihre Dateien an
                                    </span>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      oder
                                    </span>{" "}
                                    <span className="block text-blue-400 font-normal">
                                      Dateien durchsuchen
                                    </span>{" "}
                                  </div>
                                </div>{" "}
                                <input
                                  type="file"
                                  className="h-full w-full opacity-0"
                                  name=""
                                  multiple
                                  accept="application/*"
                                  onChange={(e) => {
                                    setSelectedFile(e.target.files[0]);
                                  }}
                                />
                              </div>
                              <div className="flex justify-between items-center text-gray-400">
                                {" "}
                                <span>
                                  Akzeptierte Bilder nur jpeg/png
                                </span>{" "}
                                <span className="flex items-center ">
                                  <i className="fa fa-lock mr-1"></i> secure
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Features
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <label className="flex items-center cursor-pointer">
                                <div className="relative">
                                  <ReactTagInput
                                    tags={features}
                                    placeholder="Type and press enter"
                                    maxTags={10}
                                    editable={true}
                                    readOnly={false}
                                    removeOnBackspace={true}
                                    onChange={(newTags) => setFeatures(newTags)}
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Ist öffentlich
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) => handleToggle(e)}
                                checked={isPublic}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Edit;
