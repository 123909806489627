import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../containers/sidebar";
import Navbar from "../../containers/navbar";
import { useHistory } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import { SelectPicker } from "rsuite";
import { useRouteMatch } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";
import Switch from "react-switch";

let numbers = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
];

function Create() {
  let history = useHistory();
  const match = useRouteMatch();
  const { user, about, loadAbout } = useContext(AuthContext);
  const [nav, setShowNav] = useState({ show: false, position: 0 });
  const [footer, setShowFooter] = useState({ show: false, position: 0 });
  const [gallery, setShowGallery] = useState({ show: false, position: 0 });
  const [news, setShowNews] = useState({ show: false, position: 0 });
  const [membership, setShowMembership] = useState({
    show: false,
    position: 0,
  });
  const [aboutUs, setShowAboutUs] = useState({ show: false, position: 0 });
  const [restaurant, setRestaurant] = useState({ show: false, position: 0 });
  const [premises, setPremises] = useState({ show: false, position: 0 });
  const [termine, setTermine] = useState({ show: false, position: 0 });
  const fetchData = () => {
    return loadAbout();
  };

  useEffect(() => {
    if (about) {
      setShowNav(about.nav);
      setShowFooter(about.footer);
      setShowMembership(about.membership);
      setShowNews(about.news);
      setShowAboutUs(about.about);
      setRestaurant(about.restaurant);
      setPremises(about.premises);
      setTermine(about.termine);
      setShowGallery(about.gallery);
    }
  }, [about]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    let data = {
      nav,
      footer,
      news,
      membership,
      about: aboutUs,
      restaurant,
      premises,
      termine,
      gallery,
    };

    Call({
      path: "home/aboutus/" + about._id,
      method: "patch",
      data,
    }).then((d) => {
      AM.success("Bearbeitet");
      fetchData();
    });
  };
  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="container float-right mx-auto">
        <form onSubmit={onHandleSubmit}>
          <div className="bg-white">
            <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex space-x-4 items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center"></div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                  >
                    Fertig
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
            <div className="max-w-xl w-full space-y-12">
              <div>
                <div className="md:grid md:grid-cols-1 ">
                  <div className="mt-5 md:mt-0 md:col-span-4">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <h2 className="mt-2 text-left text-1xl font-bold text-green-800">
                          Inhalt
                        </h2>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Navigationsleiste anzeigen
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setShowNav({ ...nav, show: !nav.show })
                                }
                                checked={nav.show}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Home anzeigen{" "}
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setShowAboutUs({
                                    ...aboutUs,
                                    show: !aboutUs.show,
                                  })
                                }
                                checked={aboutUs.show}
                              />

                              <SelectPicker
                                placeholder=""
                                data={numbers}
                                searchable={false}
                                value={aboutUs.position}
                                style={{ width: 60, marginLeft: 20 }}
                                cleanable={false}
                                onChange={(v, e) =>
                                  setShowAboutUs({ ...aboutUs, position: v })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Anlage anzeigen{" "}
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setPremises({
                                    ...premises,
                                    show: !premises.show,
                                  })
                                }
                                checked={premises.show}
                              />
                              <SelectPicker
                                placeholder=""
                                data={numbers}
                                searchable={false}
                                defaultValue={premises.position}
                                value={premises.position}
                                style={{ width: 60, marginLeft: 20 }}
                                cleanable={false}
                                onChange={(v, e) =>
                                  setPremises({
                                    ...premises,
                                    position: v,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Nachrichten anzeigen
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setShowNews({ ...news, show: !news.show })
                                }
                                checked={news.show}
                              />
                              <SelectPicker
                                placeholder=""
                                data={numbers}
                                searchable={false}
                                value={news.position}
                                style={{ width: 60, marginLeft: 20 }}
                                cleanable={false}
                                onChange={(v, e) =>
                                  setShowNews({ ...news, position: v })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Termine anzeigen
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setTermine({
                                    ...termine,
                                    show: !termine.show,
                                  })
                                }
                                checked={termine.show}
                              />
                              <SelectPicker
                                placeholder=""
                                data={numbers}
                                searchable={false}
                                defaultValue={termine.position}
                                value={termine.position}
                                style={{ width: 60, marginLeft: 20 }}
                                cleanable={false}
                                onChange={(v, e) =>
                                  setTermine({
                                    ...termine,
                                    position: v,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Vereinsheim anzeigen
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setRestaurant({
                                    ...restaurant,
                                    show: !restaurant.show,
                                  })
                                }
                                checked={restaurant.show}
                              />
                              <SelectPicker
                                placeholder=""
                                data={numbers}
                                searchable={false}
                                defaultValue={restaurant.position}
                                value={restaurant.position}
                                style={{ width: 60, marginLeft: 20 }}
                                cleanable={false}
                                onChange={(v, e) =>
                                  setRestaurant({
                                    ...restaurant,
                                    position: v,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Galerie
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setShowGallery({
                                    ...gallery,
                                    show: !gallery.show,
                                  })
                                }
                                checked={gallery.show}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Fußzeile anzeigen
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) =>
                                  setShowFooter({
                                    ...footer,
                                    show: !footer.show,
                                  })
                                }
                                checked={footer.show}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Create;
