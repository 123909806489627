import Call from "../util/Call";

const getAdminDashboard = () => {};

const removeAdmin = async (id) => {
  return Call({
    path: `auth/remove/${id}`,
    method: "DELETE",
  });
};

async function fetchAdminData() {
  return Call({
    path: "auth/admins",
    method: "GET",
  });
}

async function create(data) {
  return Call({
    path: "auth/createAdmin",
    method: "POST",
    data,
  });
}

export default {
  getAdminDashboard,
  removeAdmin,
  fetchAdminData,
  create,
};
