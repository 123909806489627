import { NotificationManager } from "react-notifications";

const obj = {
  error: (m) => {
    return NotificationManager.error(m, null, 3000);
  },

  success: (m) => {
    return NotificationManager.success(m, null, 3000);
  },
  warning: (m) => {
    return NotificationManager.warning(m, null, 3000);
  },
};

export default obj;
