import React, { useContext, useState, useEffect } from "react";
import Sidebar from "../../containers/sidebar";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../../containers/navbar";
import { authHeader } from "../../functions/users";
import { useHistory } from "react-router-dom";
import Home from "../Home";
import { UserContext } from "../../contexts/UserContext";
import FormParser, { validate } from "../../util/FormParser";
import { NotificationContainer } from "react-notifications";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import { AuthContext } from "../../contexts/AuthContext";
import Switch from "react-switch";

function Create() {
  let history = useHistory();
  const { user, loadAbout } = useContext(AuthContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [aboutFile, setAboutFile] = useState(null);
  const [privacyFile, setPrivacyFile] = useState(null);
  const [content, setContent] = useState("");

  const onChangeContent = (e) => {
    let content = e.target.value;
    setContent(content);
  };
  const handleLineBreak = (e) => {
    e.preventDefault(e);
    setContent(content + "<br/>");
  };
  const handleParagraph = (e) => {
    e.preventDefault(e);
    setContent(content + "<p>Bitte schreiben Sie Ihren Absatz hier</p>");
  };
  const handleBold = (e) => {
    e.preventDefault(e);
    setContent(
      content +
        "<strong>Bitte Schreiben Sie hier, um den Text fett zu machen</strong>"
    );
  };

  useEffect(() => {
    loadAbout().then((d) => {
      if (d) {
        history.push("/about-us");
      }
    });
  }, []);

  const onHandleSubmit = (e) => {
    let values = FormParser(e);
    values.terms = content;
    let formData = new FormData();
    if (!selectedFile) {
      AM.error("Es muss eine Logo ausgewählt sein");
      return;
    }
    if (!aboutFile) {
      AM.error("Es muss eine Home bild ausgewählt sein");
    }

    if (!coverFile) {
      AM.error("Es muss eine Hintergrundbild ausgewählt sein");
      return;
    }

    if (!privacyFile) {
      AM.error("Es muss eine Datenschutzbestimmung ausgewählt sein");
      return;
    }

    formData.append("logo", selectedFile);
    formData.append("aboutimage", aboutFile);
    formData.append("cover", coverFile);
    formData.append("privacy", privacyFile);

    for (let v in values) {
      formData.append(v, values[v]);
    }

    validate(
      values,
      (d) => {
        if (d) {
          Call({
            path: "home/aboutus",
            method: "post",
            data: formData,
          }).then((d) => {
            e.target.reset();
            setSelectedFile(null);
            // AM.success("Created About");
            loadAbout().then((d) => {
              history.push("/about-us");
            });
          });
        }
      },
      []
    );
  };
  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="container float-right mx-auto">
        <form onSubmit={onHandleSubmit}>
          <div className="bg-white">
            <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex space-x-4 items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center"></div>

                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <a
                    href="/about-us"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md  text-sm font-medium text-black bg-transparent hover:bg-gray-200"
                  >
                    Abbrechen
                  </a>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                  >
                    Fertig
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
            <div className="max-w-xl w-full space-y-12">
              <div>
                <div className="md:grid md:grid-cols-1 ">
                  <div className="mt-5 md:mt-0 md:col-span-4">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <h2 className="mt-2 text-left text-1xl font-bold text-green-800">
                          Verein erstellen
                        </h2>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Vereinsname
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="60"
                                maxLength="60"
                                name="club_name"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Vereinsname"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Beschreibung Linke Seite
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <textarea
                                type="text"
                                required
                                size="800"
                                rows="20"
                                maxLength="800"
                                name="content1"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Beschreibung Linke Seite"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Beschreibung Rechte Seite
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <textarea
                                type="text"
                                required
                                size="800"
                                rows="20"
                                maxLength="800"
                                name="content2"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Beschreibung Rechte Seite"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Zitat
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <textarea
                                type="text"
                                required
                                size="800"
                                rows="20"
                                maxLength="800"
                                name="description"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Zitat"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Zitiert von
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <textarea
                                type="text"
                                required
                                size="800"
                                rows="20"
                                maxLength="800"
                                name="quotedby"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Zitiert von"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Logo
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md">
                              <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                <div className="absolute">
                                  <div className="flex flex-col items-center ">
                                    {" "}
                                    <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      Hängen Sie hier Ihre Dateien an
                                    </span>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      oder
                                    </span>{" "}
                                    <span className="block text-blue-400 font-normal">
                                      Dateien durchsuchen
                                    </span>{" "}
                                  </div>
                                </div>{" "}
                                <input
                                  type="file"
                                  className="h-full w-full opacity-0"
                                  name=""
                                  accept="image/*"
                                  multiple
                                  onChange={(e) => {
                                    setSelectedFile(e.target.files[0]);
                                  }}
                                />
                              </div>
                              <div className="flex justify-between items-center text-gray-400">
                                {" "}
                                <span>
                                  Akzeptierte Bilder nur jpeg/png
                                </span>{" "}
                                <span className="flex items-center ">
                                  <i className="fa fa-lock mr-1"></i> secure
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Hintergrundbild
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md">
                              <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                <div className="absolute">
                                  <div className="flex flex-col items-center ">
                                    {" "}
                                    <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      Hängen Sie hier Ihre Dateien an
                                    </span>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      oder
                                    </span>{" "}
                                    <span className="block text-blue-400 font-normal">
                                      Dateien durchsuchen
                                    </span>{" "}
                                  </div>
                                </div>{" "}
                                <input
                                  type="file"
                                  className="h-full w-full opacity-0"
                                  name=""
                                  accept="image/*"
                                  multiple
                                  onChange={(e) => {
                                    setCoverFile(e.target.files[0]);
                                  }}
                                />
                              </div>
                              <div className="flex justify-between items-center text-gray-400">
                                {" "}
                                <span>
                                  Akzeptierte Bilder nur jpeg/png
                                </span>{" "}
                                <span className="flex items-center ">
                                  <i className="fa fa-lock mr-1"></i> secure
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Home-Bild
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md">
                              <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                <div className="absolute">
                                  <div className="flex flex-col items-center ">
                                    {" "}
                                    <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      Hängen Sie hier Ihre Dateien an
                                    </span>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      oder
                                    </span>{" "}
                                    <span className="block text-blue-400 font-normal">
                                      Dateien durchsuchen
                                    </span>{" "}
                                  </div>
                                </div>{" "}
                                <input
                                  type="file"
                                  className="h-full w-full opacity-0"
                                  name=""
                                  accept="image/*"
                                  multiple
                                  onChange={(e) => {
                                    setAboutFile(e.target.files[0]);
                                  }}
                                />
                              </div>
                              <div className="flex justify-between items-center text-gray-400">
                                {" "}
                                <span>
                                  Akzeptierte Bilder nur jpeg/png
                                </span>{" "}
                                <span className="flex items-center ">
                                  <i className="fa fa-lock mr-1"></i> secure
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Telefonnummer
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="60"
                                maxLength="60"
                                name="phone"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              E-Mail
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="60"
                                maxLength="60"
                                name="email"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              adresse
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="90"
                                maxLength="90"
                                name="address"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="address"
                              />
                            </div>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="90"
                                maxLength="90"
                                name="postalcode_city"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Postleitzahl und Stadt"
                              />
                            </div>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="90"
                                maxLength="90"
                                name="operator"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Betreiber"
                              />
                            </div>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="90"
                                maxLength="90"
                                name="company_name"
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Firmaname"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Impressum
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="flex space-x-4">
                              <button
                                onClick={handleLineBreak}
                                className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                              >
                                Linie brechen
                              </button>
                              <button
                                onClick={handleParagraph}
                                className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                              >
                                Absatz
                              </button>
                              <button
                                onClick={handleBold}
                                className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                              >
                                Fett
                              </button>
                            </div>

                            <div className="mt-1 flex rounded-md shadow-sm">
                              <textarea
                                onChange={onChangeContent}
                                value={content}
                                rows="20"
                                className="resize-y rounded-md focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Bitte geben Sie hier Ihren Nachrichteninhalt ein"
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Datenschutzbestimmungen
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md">
                              <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                <div className="absolute">
                                  <div className="flex flex-col items-center ">
                                    <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      Hängen Sie hier Ihre Dateien an
                                    </span>{" "}
                                    <span className="block text-gray-400 font-normal">
                                      oder
                                    </span>{" "}
                                    <span className="block text-blue-400 font-normal">
                                      Dateien durchsuchen
                                    </span>{" "}
                                  </div>
                                </div>{" "}
                                <input
                                  type="file"
                                  className="h-full w-full opacity-0"
                                  name=""
                                  accept="application/*"
                                  multiple
                                  onChange={(e) => {
                                    setPrivacyFile(e.target.files[0]);
                                  }}
                                />
                              </div>
                              <div className="flex justify-between items-center text-gray-400">
                                <span>Akzeptierte Bilder nur pdf/docx</span>
                                <span className="flex items-center ">
                                  <i className="fa fa-lock mr-1"></i> secure
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Create;
