import ACTION from "../actions/type";

export default function authReducer(state, action) {
  const result = action.body;

  switch (action.type) {
    case `${ACTION.LOGIN_SUCCESS}`:
      return {
        ...state,
        user: result.user,
      };

    case `${ACTION.USER_LOAD}`:
      return {
        ...state,
        user: result,
      };

    case `${ACTION.ABOUT_LOAD}`:
      return {
        ...state,
        about: result,
      };

    case `${ACTION.RESTAURANT_LOAD}`:
      return {
        ...state,
        restaurant: result,
      };

    case `${ACTION.PREMISES_LOAD}`:
      return {
        ...state,
        premises: result,
      };

    default:
      return state;
  }
}
