import logo from "./logo.svg";
import Login from "./components/auth/Login";
import AdminManagement from "./components/AdminManagement";
import CreateAdmin from "./components/AdminManagement/createAdmin";
import CreateNews from "./components/NewsManagement/createNews";
import CreateMembership from "./components/MembershipManagement/createMembership";
import NewsManagement from "./components/NewsManagement";
import MembershipManagement from "./components/MembershipManagement";
import EditAdmin from "./components/AdminManagement/editAdmin";
import CreatePassword from "./components/PasswordManagement";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UserContextProvider } from "./contexts/UserContext";
import PrivateLayout from "./containers/layouts/PrivateLayout";
import { AuthContextProvider } from "./contexts/AuthContext";
import "./App.css";
import "rsuite/dist/rsuite.min.css";
import EditNews from "./components/NewsManagement/edit";
import EditMembership from "./components/MembershipManagement/edit";
import CreateAbout from "./components/AboutManagement/create";
import EditAbout from "./components/AboutManagement/edit";
import About from "./components/AboutManagement";
import Inhalt from "./components/AboutManagement/inhalt";
import Restaurant from "./components/Resturant/edit";
import CreateRestaurant from "./components/Resturant/create";
import Premises from "./components/Premises";
import CreatePremises from "./components/Premises/create";
import EditPremises from "./components/Premises/edit";
import Termine from "./components/termine";
import CreateTermine from "./components/termine/create";
import EditTermine from "./components/termine/edit";
import Gallery from "./components/gallery";
import File from "./components/gallery/files";
function App() {
  return (
    <AuthContextProvider>
      <UserContextProvider>
        <div className="App">
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route path="/admin-management">
                <PrivateLayout>
                  <AdminManagement />
                </PrivateLayout>
              </Route>
              <Route path="/create-admin">
                <PrivateLayout>
                  <CreateAdmin />
                </PrivateLayout>
              </Route>
              <Route path="/edit-admin/:id">
                <PrivateLayout>
                  <EditAdmin />
                </PrivateLayout>
              </Route>
              <Route path="/news-management">
                <PrivateLayout>
                  <NewsManagement />
                </PrivateLayout>
              </Route>
              <Route path="/create-news">
                <PrivateLayout>
                  <CreateNews />
                </PrivateLayout>
              </Route>
              <Route path="/edit-news/:id">
                <PrivateLayout>
                  <EditNews />
                </PrivateLayout>
              </Route>
              <Route path="/membership-management">
                <PrivateLayout>
                  <MembershipManagement />
                </PrivateLayout>
              </Route>
              <Route path="/create-membership">
                <PrivateLayout>
                  <CreateMembership />
                </PrivateLayout>
              </Route>
              <Route path="/edit-membership/:id">
                <PrivateLayout>
                  <EditMembership />
                </PrivateLayout>
              </Route>
              {/*About */}
              <Route path="/homepage-inhalt">
                <PrivateLayout>
                  <Inhalt />
                </PrivateLayout>
              </Route>
              <Route path="/create-about">
                <PrivateLayout>
                  <CreateAbout />
                </PrivateLayout>
              </Route>
              <Route path="/about-us">
                <PrivateLayout>
                  <EditAbout />
                </PrivateLayout>
              </Route>
              <Route path="/create-password/:token">
                <CreatePassword />
              </Route>
              <Route path="/create-restaurant">
                <PrivateLayout>
                  <CreateRestaurant />
                </PrivateLayout>
              </Route>
              <Route path="/restaurant">
                <PrivateLayout>
                  <Restaurant />
                </PrivateLayout>
              </Route>
              <Route path="/create-premises">
                <PrivateLayout>
                  <CreatePremises />
                </PrivateLayout>
              </Route>
              <Route path="/premises">
                <PrivateLayout>
                  <Premises />
                </PrivateLayout>
              </Route>
              <Route path="/edit-premises/:id">
                <PrivateLayout>
                  <EditPremises />
                </PrivateLayout>
              </Route>
              <Route path="/termine">
                <PrivateLayout>
                  <Termine />
                </PrivateLayout>
              </Route>
              <Route path="/create-termine">
                <PrivateLayout>
                  <CreateTermine />
                </PrivateLayout>
              </Route>
              <Route path="/edit-termine/:id">
                <PrivateLayout>
                  <EditTermine />
                </PrivateLayout>
              </Route>
              <Route path="/gallery">
                <PrivateLayout>
                  <Gallery />
                </PrivateLayout>
              </Route>
              <Route path="/file/:id">
                <PrivateLayout>
                  <File />
                </PrivateLayout>
              </Route>
            </Switch>
          </BrowserRouter>
          {/* <Routes /> */}
          {/* <Home /> */}
        </div>
        {/* </body> */}
      </UserContextProvider>
    </AuthContextProvider>
  );
}

export default App;
