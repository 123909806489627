import React from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import Switch from "react-switch";
import Call from "../../util/Call";
import { Pagination } from "rsuite";
import "rsuite-table/dist/css/rsuite-table.css";
import {
  HiDotsHorizontal,
  HiExclamation,
  HiOutlineTrash,
  HiDuplicate,
  HiEye,
  HiPencil,
} from "react-icons/hi";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MembershipTable(props) {
  const [page, setPage] = React.useState(1);

  let changeStatus = (id, status) => {
    if (window.confirm("Are you sure")) {
      Call({
        path: "home/membership/" + id,
        method: "patch",
        data: { isPublic: !status },
      }).then((d) => props.changePage(page));
    }
  };

  let remove = (id) => {
    if (window.confirm("Are you sure")) {
      Call({
        path: "home/membership/" + id,
        method: "delete",
      }).then((d) => props.changePage(page));
    }
  };

  const pageChange = (page) => {
    setPage(page);
    props.changePage(page);
  };
  return (
    <div style={{ zIndex: 0 }}>
      <Table autoHeight={true} data={props.data} loading={props.loading}>
        <Column width={200} align="left">
          <HeaderCell>Titel</HeaderCell>
          <Cell dataKey="title" />
        </Column>

        <Column width={200}>
          <HeaderCell>Ist öffentlich</HeaderCell>

          <Cell>
            {(rowData) => {
              return (
                <Switch
                  checkedIcon={false}
                  onChange={(e) => changeStatus(rowData._id, rowData.isPublic)}
                  checked={rowData.isPublic}
                />
              );
            }}
          </Cell>
        </Column>
        <Column width={240}>
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <div className="flex">
                  <Menu>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={{
                            pathname: `/edit-membership/${rowData._id}`,
                            state: [{ id: rowData._id }],
                          }}
                        >
                          <button
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "flex px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            <HiPencil
                              className="h-5 w-4 text-green-600"
                              aria-hidden="true"
                            />
                            Bearbeiten
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                  </Menu>
                  <Menu>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            remove(rowData._id);
                          }}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "flex px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          <HiOutlineTrash
                            className="h-5 w-4 text-green-600"
                            aria-hidden="true"
                          />
                          Löschen
                        </button>
                      )}
                    </Menu.Item>
                  </Menu>
                </div>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "|", "pager", "skip"]}
          total={props.total}
          limit={props.limit}
          activePage={page}
          onChangePage={pageChange}
        />
      </div>
    </div>
  );
}
