import React, { useState, useRef } from "react";
import { Modal, Button } from "rsuite";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import FormParser, { validate } from "../../util/FormParser";

const App = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const mForm = useRef(null);

  const onHandleSubmit = (e) => {
    let values = FormParser(e);
    let formData = new FormData();
    if (!selectedFile) {
      AM.error("Es muss ein Bild ausgewählt sein");
      return;
    }
    formData.append("file", selectedFile);

    for (let v in values) {
      formData.append(v, values[v]);
    }

    validate(
      values,
      (d) => {
        if (d) {
          Call({
            path: "home/aboutus/" + props.id + "/member",
            method: "post",
            data: formData,
          }).then((d) => {
            e.target.reset();
            setSelectedFile(null);
            AM.success("Created Member");
            props.handleOk(d);
          });
        }
      },
      ["email", "phone"]
    );
  };

  return (
    <div className="modal-container">
      <Modal open={props.open} onClose={props.handleClose}>
        <form id="board_form" ref={mForm} onSubmit={onHandleSubmit}>
          <Modal.Header>
            <Modal.Title>Board Members</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
              <div className="max-w-xl w-full space-y-12">
                <div>
                  <div className="md:grid md:grid-cols-1 ">
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Name
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  required
                                  size="60"
                                  maxLength="60"
                                  name="name"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder=" name"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Position
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  required
                                  size="60"
                                  maxLength="60"
                                  name="role"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder="Role"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Bild
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md">
                                <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                  <div className="absolute">
                                    <div className="flex flex-col items-center ">
                                      {" "}
                                      <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                      <span className="block text-gray-400 font-normal">
                                        Hängen Sie hier Ihre Dateien an
                                      </span>{" "}
                                      <span className="block text-gray-400 font-normal">
                                        oder
                                      </span>{" "}
                                      <span className="block text-blue-400 font-normal">
                                        Dateien durchsuchen
                                      </span>{" "}
                                    </div>
                                  </div>{" "}
                                  <input
                                    type="file"
                                    className="h-full w-full opacity-0"
                                    name=""
                                    accept="image/*"
                                    multiple
                                    onChange={(e) => {
                                      setSelectedFile(e.target.files[0]);
                                    }}
                                  />
                                </div>
                                <div className="flex justify-between items-center text-gray-400">
                                  {" "}
                                  <span>
                                    Akzeptierte Bilder nur jpeg/png
                                  </span>{" "}
                                  <span className="flex items-center ">
                                    <i className="fa fa-lock mr-1"></i> secure
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                E-Mail
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="email"
                                  name="email"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder=" Optional"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Telefonnummer
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  name="phone"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder=" Optional"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button>Ok</button>
            <Button onClick={props.handleClose} appearance="subtle">
              Abbrechen
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default App;
