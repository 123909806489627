import React, { createContext, useReducer } from "react";
import authReducer from "../reducers/auth";
import ACTION from "../actions/type";
import Service from "../services/auth.service";
const initialState = {
  roles: [],
  role_pagination: {
    total: 0,
    limit: 10,
    start: 0,
  },
  loading: false,
  user: null,
  about: null,
  restaurant: null,
  premises: null,
};

export const AuthContext = createContext(initialState);

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  async function updateRole(id, data) {
    let body = await Service.updateRole(id, data);
    dispatch({ type: ACTION.UPDATE_ROLE, body });
    return body;
  }

  function setLoading() {
    dispatch({ type: ACTION.SET_LOADING });
  }

  function resetLoading() {
    dispatch({ type: ACTION.RESET_LOADING });
  }

  function addRole(payload) {
    return new Promise((resolve, reject) => {
      Service.addRole(payload)
        .then((body) => {
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function listRole(query) {
    return new Promise((resolve, reject) => {
      Service.listRole(query)
        .then((body) => {
          dispatch({ type: ACTION.LIST_ROLE, body });
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function handleLogin(data) {
    return new Promise((resolve, reject) => {
      Service.login(data)
        .then((body) => {
          dispatch({ type: ACTION.LOGIN_SUCCESS, body });
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function me() {
    await loadAbout();
    await loadRestaurant();
    await loadPremises();
    return Service.user().then((d) =>
      dispatch({ type: ACTION.USER_LOAD, body: d })
    );
  }

  async function loadAbout() {
    let about = await Service.about();
    dispatch({ type: ACTION.ABOUT_LOAD, body: about });
    return about;
  }

  async function loadRestaurant() {
    let d = await Service.restaurant();
    dispatch({ type: ACTION.RESTAURANT_LOAD, body: d });
    return d;
  }

  async function loadPremises() {
    let d = await Service.premises();
    dispatch({ type: ACTION.PREMISES_LOAD, body: d });
    return d;
  }

  function logout() {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
  }

  function changeAbout(params) {
    dispatch({ type: ACTION.ABOUT_LOAD, body: { ...state.about, ...params } });
  }

  function changeRestaurant(params) {
    dispatch({
      type: ACTION.RESTAURANT_LOAD,
      body: { ...state.restaurant, ...params },
    });
  }
  function changePremises(params) {
    dispatch({
      type: ACTION.PREMISES_LOAD,
      body: { ...state.premises, ...params },
    });
  }
  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        about: state.about,
        loading: state.loading,
        role_pagination: state.role_pagination,
        handleLogin,
        updateRole,
        listRole,
        addRole,
        setLoading,
        resetLoading,
        me,
        logout,
        loadAbout,
        changeAbout,
        changeRestaurant,
        restaurant: state.restaurant,
        loadRestaurant,
        premises: state.premises,
        changePremises,
        loadPremises,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
