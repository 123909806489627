import React, { useEffect, useState, useContext } from "react";
import { Fragment, useRef } from "react";

import Sidebar from "../../containers/sidebar";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

import { AuthContext } from "../../contexts/AuthContext";
import { UserContext } from "../../contexts/UserContext";

import {
  HiDotsHorizontal,
  HiExclamation,
  HiOutlineTrash,
  HiDuplicate,
  HiEye,
  HiPencil,
} from "react-icons/hi";
import dayjs from "dayjs";
// import { useDispatch, useSelector } from "react-redux";

import Navbar from "../../containers/navbar";
import { Link } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
dayjs().format();

function AdminManagement() {
  const { user } = useContext(AuthContext);
  const { removeAdmin, fetchAdmins } = useContext(UserContext);

  const [adminData, setAdminData] = useState([]);
  const [showView, setShowView] = useState(false);
  // const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const cancelButtonRef = useRef(null);
  const handleDelete = (id) => {
    setOpen(true);
    setId(id);
    console.log(id);
  };
  // async function handleEdit(id) {
  //   console.log(id);
  //   this.props.history.push({
  //     pathname: "/edit-admin",
  //     state: [{ id: id }],
  //   });
  // }

  const handleRemoveAdmin = () => {
    removeAdmin(id);
  };

  const handleVisibility = () => {
    setShowView(true);
  };

  useEffect(() => {
    fetchAdmins().then((d) => {
      setAdminData(d);
    });
  }, [user]);
  useEffect(() => {
    document.title = "Inhaltsverwaltung";
  }, []);
  return (
    <>
      <Sidebar />

      <Navbar />
      {user ? (
        <>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 xl:max-w-5xl xl:px-0  flex">
            <main className="w-full max-w-8xl mx-auto font-lexend">
              <div className="bg-white">
                <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                      {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                    </div>
                    {user.role === "Superadministrator" ? (
                      <Link to="/create-admin">
                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <button className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600">
                            Neue Verwalterin
                          </button>
                        </div>
                      </Link>
                    ) : (
                      <div className="text-sm">
                        ‘Edit’, ’Delete’, ’New Admin’ functions are only
                        available for ’Super’ Admin. &nbsp;
                        <a href="/" className="text-green-500">
                          <u>See More</u>
                        </a>
                        <HiExclamation className="w-5 h-5 text-green-500" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="flex flex-col">
                <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block  sm:px-6 lg:px-8">
                    <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-y-auto md:overflow-scroll h-80">
                      <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              <div className="flex items-center h-4">
                                <input
                                  id="admins"
                                  name="admins"
                                  type="checkbox"
                                  className="focus:ring-green-400 h-3 w-3 text-green-600 border-green-300 rounded"
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Verwaltungsnummer
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Vorname
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Nachname
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Startdatum
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Enddatum
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                              <span className="sr-only">Bearbeiten</span>
                            </th>
                          </tr>
                        </thead>

                        {/* <div className=""> */}

                        {adminData.length > 0 ? (
                          adminData.map((person) => (
                            <tbody className="bg-white divide-y divide-gray-200">
                              <tr
                                key={person._id}
                                className="hover:bg-green-50"
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                  <div className="flex items-center h-4">
                                    <input
                                      id="admins"
                                      name="admins"
                                      type="checkbox"
                                      className="focus:ring-green-400 h-3 w-3 text-green-600 border-green-300 rounded"
                                    />
                                  </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                  {person.admin_number}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                  {person.first_name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                  {person.last_name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                  {dayjs(person.start_date).format(
                                    "DD.MM.YYYY"
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                  {dayjs(person.end_date).format("DD.MM.YYYY")}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                  <Menu as="div" className="ml-6 relative">
                                    <div>
                                      <Menu.Button className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:none focus:none">
                                        <HiDotsHorizontal
                                          className="justify-center h-4 w-4"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95"
                                    >
                                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              className={classNames(
                                                active ? "bg-gray-100" : "",
                                                "flex px-4 py-2 text-sm text-gray-700"
                                              )}
                                              onClick={handleVisibility}
                                            >
                                              <HiEye
                                                className="h-5 w-4 text-green-600"
                                                aria-hidden="true"
                                              />
                                              Mehr sehen
                                            </button>
                                          )}
                                        </Menu.Item>
                                        {user.role === "Superadministrator" ? (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <Link
                                                to={{
                                                  pathname: `/edit-admin/${person._id}`,
                                                  state: [{ id: person._id }],
                                                }}
                                              >
                                                <button
                                                  className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "flex px-4 py-2 text-sm text-gray-700"
                                                  )}
                                                >
                                                  <HiPencil
                                                    className="h-5 w-4 text-green-600"
                                                    aria-hidden="true"
                                                  />
                                                  Bearbeiten
                                                </button>
                                              </Link>
                                            )}
                                          </Menu.Item>
                                        ) : (
                                          ""
                                        )}

                                        {user.role === "Superadministrator" ? (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                onClick={() => {
                                                  handleDelete(person._id);
                                                }}
                                                className={classNames(
                                                  active ? "bg-gray-100" : "",
                                                  "flex px-4 py-2 text-sm text-gray-700"
                                                )}
                                              >
                                                <HiOutlineTrash
                                                  className="h-5 w-4 text-green-600"
                                                  aria-hidden="true"
                                                />
                                                Löschen
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ) : (
                                          ""
                                        )}
                                        {user.role === "Superadministrator" ? (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                className={classNames(
                                                  active ? "bg-gray-100" : "",
                                                  "flex px-4 py-2 text-sm text-gray-700"
                                                )}
                                              >
                                                <HiDuplicate
                                                  className="h-5 w-4 text-green-600"
                                                  aria-hidden="true"
                                                />
                                                Duplizieren
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ) : (
                                          ""
                                        )}
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </td>
                              </tr>
                              {showView ? (
                                <React.Fragment>
                                  {" "}
                                  <tr>
                                    <th className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                      <div className="flex items-center h-4"></div>
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      DOB
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Address
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Phone Number
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      E-mail
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      Status
                                    </th>
                                  </tr>
                                  <tr className="hover:bg-green-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black"></td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                      {person.dob}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                      {person.address}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                      {person.phone_number}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                      {person.email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
                                      {person.isActive.toString()}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ) : null}
                            </tbody>
                          ))
                        ) : (
                          <tbody>
                            <tr className="hover:bg-green-50">
                              <td
                                colSpan="7"
                                className="text-l font-regular mb-2 text-gray-500 text-shadow "
                              >
                                Keine Daten verfügbar
                              </td>
                            </tr>
                          </tbody>
                        )}

                        {/* </div> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-10 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg leading-6 font-medium text-gray-900"
                          >
                            Konto löschen
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Sind Sie sicher, dass Sie diesen Administrator
                              löschen wollen? Alle Admin-Daten werden dauerhaft
                              entfernt. Diese Aktion kann nicht rückgängig
                              gemacht werden.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => handleRemoveAdmin()}
                      >
                        <HiOutlineTrash
                          className="h-5 w-4 text-white-600"
                          aria-hidden="true"
                        />
                        Löschen
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-green-50 focus:outline-none   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AdminManagement;
