import ACTION from "../actions/type";

export default function userReducer(state, action) {
  const result = action.body;

  switch (action.type) {
    case `${ACTION.USER_ADMIN}`:
      return {
        ...state,
        admins: result,
      };
    case `${ACTION.USER_CREATE}`:
      return {
        ...state,
        user: result,
      };

    case `${ACTION.REMOVE_ADMIN}`:
      return {
        ...state,
      };

    default:
      return state;
  }
}
