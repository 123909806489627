import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "rsuite";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import FormParser, { validate } from "../../util/FormParser";
import Switch from "react-switch";

const App = (props) => {
  const mForm = useRef(null);
  const [isPublic, setIsPublic] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);

  let add = (e) => {
    let values = FormParser(e);
    values.isPublic = isPublic;
    if (!selectedFile) return AM.error("Es muss ein Bild ausgewählt sein");
    let formData = new FormData();
    formData.append("file", selectedFile);
    for (let v in values) {
      formData.append(v, values[v]);
    }

    formData.append("gallery", props.id);

    Call({ path: "home/gallery/file", method: "post", data: formData })
      .then((d) => {
        props.handleOk(d);
      })
      .catch((err) => {
        AM.error(err.message);
      });
  };

  return (
    <div className="modal-container">
      <Modal open={props.open} onClose={props.handleClose}>
        <form id="board_form" ref={mForm} onSubmit={add}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
              <div className="max-w-xl w-full space-y-12">
                <div>
                  <div className="md:grid md:grid-cols-1 ">
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Titel
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  size="25"
                                  maxLength="25"
                                  name="title"
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder="Title"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 mt-4 mb-2">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Bild
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  required
                                  name="file"
                                  type="file"
                                  multiple
                                  onChange={(e) => {
                                    setSelectedFile(e.target.files[0]);
                                  }}
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Ist öffentlich
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <Switch
                                  checkedIcon={false}
                                  onChange={(e) => setIsPublic(!isPublic)}
                                  checked={isPublic}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button>Ok</button>
            <Button onClick={props.handleClose} appearance="subtle">
              Abbrechen
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default App;
