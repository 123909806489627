const obj = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",
  UPDATE_ROLE: "update_role",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  SET_MESSAGE: "SET_MESSAGE",
  SET_LOADING: "set_loading",
  RESET_LOADING: "reset_loading",
  LIST_ROLE: "list_role",
  USER_ADMIN: "user_admin",
  REMOVE_ADMIN: "remove_admin",
  USER_LOAD: "user_load",
  USER_CREATE: "user_create",
  ABOUT_LOAD: "about_load",
  RESTAURANT_LOAD: "restaurant_load",
  PREMISES_LOAD: "premises_load",
};

export default obj;
