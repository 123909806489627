import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "rsuite";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import FormParser, { validate } from "../../util/FormParser";
import Switch from "react-switch";

const App = (props) => {
  const mForm = useRef(null);
  const [name, setName] = useState("");
  const [isPublic, setIsPublic] = useState(props.isPublic);

  useEffect(() => {
    setName(props.name);
    setIsPublic(props.isPublic);
  }, []);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    let values = {
      name,
      isPublic,
    };
    validate(
      values,
      (d) => {
        if (d) {
          Call({
            path: "home/gallery/" + props.id,
            method: "patch",
            data: values,
          }).then((d) => {
            e.target.reset();
            AM.success("Bearbeitet");
            props.handleOk(d);
          });
        }
      },
      ["isPublic"]
    );
  };

  return (
    <div className="modal-container">
      <Modal open={props.open} onClose={props.handleClose}>
        <form id="board_form" ref={mForm} onSubmit={onHandleSubmit}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
              <div className="max-w-xl w-full space-y-12">
                <div>
                  <div className="md:grid md:grid-cols-1 ">
                    <div className="mt-5 md:mt-0 md:col-span-4">
                      <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Name
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                  type="text"
                                  required
                                  size="25"
                                  maxLength="25"
                                  name="name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 gap-0 ">
                            <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                              {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                              <div className=" text-sm font-medium text-gray-700">
                                Ist öffentlich
                              </div>
                              {/* </div> */}
                            </div>
                            <div className="col-span-4 sm:col-span-4">
                              <div className="mt-1 flex rounded-md shadow-sm">
                                <Switch
                                  checkedIcon={false}
                                  onChange={(e) => setIsPublic(!isPublic)}
                                  checked={isPublic}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button>Ok</button>
            <Button onClick={props.handleClose} appearance="subtle">
              Abbrechen
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default App;
