import Call from "../util/Call";

const login = (data) => {
  return Call({
    path: "auth/login",
    method: "post",
    data,
  });
};

const logout = () => {
  localStorage.removeItem("token");
};

const user = () => {
  return Call({
    path: "auth/me",
    method: "get",
  });
};

const about = () => {
  return Call({
    path: "home/aboutus/active",
    method: "get",
  });
};

const restaurant = () => {
  return Call({
    path: "home/restaurant/active",
    method: "get",
  });
};

const premises = () => {
  return Call({
    path: "home/premises/active",
    method: "get",
  });
};

export default {
  login,
  logout,
  user,
  about,
  restaurant,
  premises,
};
