import { useEffect, useState, useContext } from "react";
import Sidebar from "../../containers/sidebar";
import Navbar from "../../containers/navbar";
import Call from "../../util/Call";
import FormParser, { validate } from "../../util/FormParser";
import Modal from "./modal";
import { useHistory } from "react-router";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import Switch from "react-switch";

import {
  HiDotsHorizontal,
  HiExclamation,
  HiOutlineTrash,
  HiDuplicate,
  HiEye,
  HiPencil,
} from "react-icons/hi";
import { NotificationContainer } from "react-notifications";

let colors = [
  "bg-gray-800",
  "bg-red-500",
  "bg-blue-800",
  "bg-green-500",
  "bg-yellow-400",
  "bg-gray-400",
  "bg-gray-100",
];

let getColor = (i) => {
  if (i > colors.length - 1) {
    let p = i % colors.length;
    return colors[p];
  } else {
    return colors[i];
  }
};

export default function Album() {
  const { user } = useContext(AuthContext);

  const [albums, setAlbums] = useState([]);
  const [album, setAlbum] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isPublic, setIsPublic] = useState(true);

  let history = useHistory();

  let list = () => {
    Call({ path: "home/gallery/", method: "get" }).then((d) => {
      console.log(d);
      setAlbums(d);
    });
  };

  useEffect(() => {
    list();
  }, []);

  let addAlbum = (e) => {
    e.preventDefault();
    let values = FormParser(e);
    validate(values, (d) => {
      if (d) {
        Call({ path: "home/gallery/", method: "post", data: values })
          .then((d) => {
            setAlbums([...albums, d]);
          })
          .catch((err) => {});
      }
    });
  };

  let handleRemove = (id) => {
    if (window.confirm("Are you sure")) {
      Call({ path: "home/gallery/" + id, method: "delete" })
        .then((d) => list())
        .catch((err) => {});
    }
  };

  return (
    <div className="bg-white">
      <Navbar />
      <Sidebar />
      <NotificationContainer />
      {album ? (
        <Modal
          name={album.name}
          show={showModal}
          isPublic={album.isPublic}
          open={showModal}
          id={album._id}
          handleClose={() => {
            setShowModal(false);
            setAlbum(null);
          }}
          handleOk={() => {
            setShowModal(false);
            setAlbum(null);
            list();
          }}
        />
      ) : (
        ""
      )}

      <div className="max-w-3xl mx-auto px-4 sm:px-6 xl:max-w-5xl xl:px-0  flex">
        <main className="w-full max-w-8xl mx-auto font-lexend">
          <div className="bg-white">
            <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="pl-60	 align-middle block sm:pl-60 lg:pl-60">
                <div className="shadow border-b border-gray-200">
                  <div className="max-w-2xl mx-auto px-4  sm:px-6 lg:max-w-7xl lg:px-8">
                    <h2 className="sr-only">Albums</h2>
                    <div>
                      {user?.role === "Superadministrator" ? (
                        <form
                          className="m-4 flex justify-left"
                          onSubmit={addAlbum}
                        >
                          <input
                            className="mr-4 px-4 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white"
                            name="name"
                            size="25"
                            maxLength="25"
                            placeholder="Name angeben"
                          />
                          <Switch
                            className="mr-4 mt-1"
                            onChange={(e) => setIsPublic(!isPublic)}
                            checked={isPublic}
                          />
                          <button className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600">
                            Hinzufügen
                          </button>
                        </form>
                      ) : (
                        <div className="text-sm">
                          ‘Edit’, ’Delete’, ’New Admin’ functions are only
                          available for ’Super’ Admin. &nbsp;
                          <a href="/" className="text-green-500">
                            <u>See More</u>
                          </a>
                          <HiExclamation className="w-5 h-5 text-green-500" />
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 ">
                      {albums?.map((album, i) => (
                        <div>
                          <div className="card flex flex-col justify-center p-2 bg-white rounded-lg shadow-2xl">
                            <div className="prod-title"></div>
                            <div className="prod-img">
                              <div
                                onClick={() =>
                                  history.push("/file/" + album._id)
                                }
                                className={`m-4 ${getColor(
                                  i
                                )} rounded-2xl items-center justify-center text-center text-white py-16 cursor-pointer	font-bold`}
                              >
                                {album.name}
                              </div>
                            </div>
                            <div className="flex py-2 px-5 flex-col md:flex-row justify-between items-center ">
                              <button
                                onClick={(e) => handleRemove(album._id)}
                                className="px-6 py-2 transition ease-in duration-200 uppercase rounded-full text-red-900 hover:bg-red-800 hover:text-white border-2 border-red-900 focus:outline-none"
                              >
                                Löschen
                              </button>
                              <button
                                onClick={(e) => {
                                  setAlbum(album);
                                  setShowModal(true);
                                }}
                                className="px-6 py-2 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-white border-2 border-blue-900 focus:outline-none text-blue-900"
                              >
                                Bearbeiten
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
