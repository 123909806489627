import React, { useContext, useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

import { AuthContext } from "../../contexts/AuthContext";

let isLogin = () => (localStorage.getItem("token") ? true : false);

const PrivateRoute = ({ component: Component, ...rest }) => {
  let history = useHistory();
  const { me } = useContext(AuthContext);
  useEffect(() => me().catch((e) => history.push("/")), []);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <>
            <Component {...props} /> <NotificationContainer />
          </>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
