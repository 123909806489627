import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../../containers/sidebar";
import { useRouteMatch } from "react-router";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../../containers/navbar";
import { authHeader } from "../../functions/users";
import { useHistory } from "react-router-dom";
import Home from "../Home";
import { validate } from "../../util/FormParser";
import { NotificationContainer } from "react-notifications";
import call from "../../util/Call";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import Switch from "react-switch";
import AM from "../../util/AlertMessage";
import { AuthContext } from "../../contexts/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Edit = (props) => {
  let history = useHistory();
  const { user } = useContext(AuthContext);

  const match = useRouteMatch();
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [isPublic, setIsPublic] = useState(false);

  const handleToggle = (e) => {
    if (isPublic) {
      setIsPublic(false);
    } else {
      setIsPublic(true);
    }
  };
  const changeDate = (e) => {
    setStartDate(e.target.value);
  };
  const onChangeContent = (e) => {
    let content = e.target.value;
    setContent(content);
  };
  const handleLineBreak = (e) => {
    e.preventDefault(e);
    setContent(content + "<br/>");
  };
  const handleParagraph = (e) => {
    e.preventDefault(e);
    setContent(content + "<p>Bitte schreiben Sie Ihren Absatz hier</p>");
  };
  const handleBold = (e) => {
    e.preventDefault(e);
    setContent(
      content +
        "<strong>Bitte Schreiben Sie hier, um den Text fett zu machen</strong>"
    );
  };
  // const pro = props.match.params;
  const id = match.params.id;

  const updateData = async (v) => {
    let data = {
      title,
      description,
      date: startDate,
      content,
      isPublic,
    };
    validate(
      data,
      async (d) => {
        if (d) {
          call({
            path: "home/termine/" + id,
            method: "patch",
            data,
          }).then((d) => {
            AM.success("Bearbeitet");
            fetchAdminData();
          });
        }
      },
      ["isPublic"]
    );
  };
  async function fetchAdminData() {
    call({ path: "home/termine/" + id, method: "GET" }).then((d) => {
      setTitle(d.title);
      setStartDate(d.date);
      setDescription(d.description);
      setContent(d.content);
      setIsPublic(d.isPublic);
    });
  }
  useEffect(() => {
    fetchAdminData();
  }, [id]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    updateData();
    window.location.replace("/termine");
  };

  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      <div className="container float-right mx-auto">
        <form onSubmit={onHandleSubmit}>
          <div className="bg-white">
            <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex space-x-4 items-center justify-between flex-wrap">
                <div className="w-0 flex-1 flex items-center">
                  {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                </div>

                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <a
                    href="/news-management"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md  text-sm font-medium text-black bg-transparent hover:bg-gray-200"
                  >
                    Abbrechen
                  </a>
                </div>
                <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                  <button
                    type="submit"
                    className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                  >
                    Fertig
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
            <div className="max-w-xl w-full space-y-12">
              <div>
                <div className="md:grid md:grid-cols-1 ">
                  <div className="mt-5 md:mt-0 md:col-span-4">
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Titel
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="60"
                                maxLength="60"
                                name="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Titel"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Datum
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-2 sm:col-span-2">
                            <input
                              type="text"
                              id="datum"
                              name="datum"
                              placeholder="DD.MM.YYYY"
                              value={startDate}
                              onChange={changeDate}
                              // onChange={(date) => setStartDate(date)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Beschreibung
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <input
                                type="text"
                                required
                                size="80"
                                maxLength="80"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                placeholder="Beschreibung"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Inhalt
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4">
                            {/* <div className="flex space-x-4">
                              <button
                                onClick={handleLineBreak}
                                className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                              >
                                Linie brechen
                              </button>
                              <button
                                onClick={handleParagraph}
                                className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                              >
                                Absatz
                              </button>
                              <button
                                onClick={handleBold}
                                className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                              >
                                Fett
                              </button>
                            </div> */}

                            <div className="mt-1 flex rounded-md shadow-sm">
                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={setContent}
                                style={{ minHeight: "500px" }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-0 ">
                          <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                            {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                            <div className=" text-sm font-medium text-gray-700">
                              Ist öffentlich
                            </div>
                            {/* </div> */}
                          </div>
                          <div className="col-span-4 sm:col-span-4 mt-2 justify-center">
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <Switch
                                checkedIcon={false}
                                onChange={(e) => handleToggle(e)}
                                checked={isPublic}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Edit;
