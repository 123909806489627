import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../containers/sidebar";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import Navbar from "../../containers/navbar";
import { authHeader } from "../../functions/users";
import { useHistory } from "react-router-dom";
import Home from "../Home";
import { UserContext } from "../../contexts/UserContext";
import FormParser, { validate } from "../../util/FormParser";
import { NotificationContainer } from "react-notifications";
import AM from "../../util/AlertMessage";
import Call from "../../util/Call";
import MemberModal from "./members";
import ExtendedMemberModal from "./extended";
import TimelineModal from "./timeline";
import DownloadModal from "./downloads";
import { useRouteMatch } from "react-router";
import { Button, Nav } from "rsuite";
import { AuthContext } from "../../contexts/AuthContext";

const CustomNav = ({ active, onSelect, ...props }) => {
  return (
    <Nav
      {...props}
      activeKey={active}
      onSelect={onSelect}
      style={{ zIndex: 1 }}
    >
      <Nav.Item eventKey="about">Home</Nav.Item>
      <Nav.Item eventKey="members">Vorstand</Nav.Item>
      <Nav.Item eventKey="othermembers">Erweiterter Vorstand</Nav.Item>
      <Nav.Item eventKey="timelines">Chronik</Nav.Item>
      <Nav.Item eventKey="downloads">Downloads</Nav.Item>
    </Nav>
  );
};

function Create() {
  let history = useHistory();
  const match = useRouteMatch();
  const { user, about, loadAbout, changeAbout } = useContext(AuthContext);

  const [active, setActive] = useState("about");

  const [selectedFile, setSelectedFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [privacyFile, setPrivacyFile] = useState(null);
  const [aboutFile, setAboutFile] = useState(null);

  //Modals
  const [member_open, setMemberOpen] = useState(false);
  const [extended_open, setExtendedOpen] = useState(false);
  const [timeline_open, setTimelineOpen] = useState(false);
  const [download_open, setDownloadOpen] = useState(false);

  // useEffect(() => {
  //   console.log(about);
  //   setAddress({ company_name: about.address.company_name });
  // }, []);
  // const changeCompanyName=(e)=>{
  //   let company_name=e.target.value;
  //   let {address}=this.state;
  //   setAddress({
  //     return address[company_name] :company_name
  //   })
  // }
  const removeItem = (i, flag) => {
    switch (flag) {
      case 0:
        if (window.confirm("Are you sure")) {
          Call({
            path: "home/aboutus/" + about._id + "/member/" + i,
            method: "delete",
          }).then((d) => loadAbout());
        }
        break;
      case 1:
        if (window.confirm("Are you sure")) {
          Call({
            path: "home/aboutus/" + about._id + "/timeline/" + i,
            method: "delete",
          }).then((d) => loadAbout());
        }
        break;

      case 2:
        if (window.confirm("Are you sure")) {
          Call({
            path: "home/aboutus/" + about._id + "/download/" + i,
            method: "delete",
          }).then((d) => loadAbout());
        }
        break;
      case 3:
        if (window.confirm("Are you sure")) {
          Call({
            path: "home/aboutus/" + about._id + "/extendedmember/" + i,
            method: "delete",
          }).then((d) => loadAbout());
        }
        break;

      default:
        break;
    }
  };

  const onChangeContent = (e) => {
    let content = e.target.value;
    changeAbout({ terms: content });
  };
  const handleLineBreak = (e) => {
    e.preventDefault(e);
    changeAbout({ terms: about.terms + "<br/>" });
  };
  const handleParagraph = (e) => {
    e.preventDefault(e);
    changeAbout({
      terms: about.terms + "<p>Bitte schreiben Sie Ihren Absatz hier</p>",
    });
  };
  const handleBold = (e) => {
    e.preventDefault(e);
    changeAbout({
      terms:
        about.terms +
        "<strong>Bitte Schreiben Sie hier, um den Text fett zu machen</strong>",
    });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    let data = {
      club_name: about.club_name,
      description: about.description,
      content1: about.content1,
      content2: about.content2,
      quotedby: about.quotedby,
      phone: about.phone,
      email: about.email,
      address: about.address,
      company_name: about.company_name,
      operator: about.operator,
      postalcode_city: about.postalcode_city,
      terms: about.terms,
    };

    validate(
      data,
      async (d) => {
        if (d) {
          if (
            selectedFile == null &&
            coverFile == null &&
            privacyFile == null &&
            aboutFile == null
          ) {
            Call({
              path: "home/aboutus/" + about._id,
              method: "patch",
              data,
            }).then((d) => {
              AM.success("Edited");
              setSelectedFile(null);
              setCoverFile(null);
              loadAbout();
            });
          } else {
            let formData = new FormData();
            formData.append("logo", selectedFile);
            formData.append("cover", coverFile);
            formData.append("privacy", privacyFile);
            formData.append("aboutimage", aboutFile);

            for (let v in data) {
              formData.append(v, data[v]);
            }
            Call({
              path: "home/aboutus/multipart/" + about._id,
              method: "patch",
              data: formData,
            }).then((d) => {
              setSelectedFile(null);
              setCoverFile(null);
              setAboutFile(null);
              setPrivacyFile(null);
              AM.success("Download bearbeitet");
              loadAbout();
            });
          }
        }
      },
      []
    );
  };
  return (
    <>
      <NotificationContainer />
      <Sidebar />
      <Navbar />
      {about ? (
        <>
          <div className="container float-right mx-auto">
            <CustomNav
              appearance="subtle"
              active={active}
              onSelect={setActive}
            />

            {active === "about" ? (
              <form onSubmit={onHandleSubmit}>
                <div className="bg-white">
                  <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex space-x-4 items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center"></div>

                      <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button
                          type="submit"
                          className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                        >
                          Fertig
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-12 px- sm:px-6 lg:px-8">
                  <div className="max-w-xl w-full space-y-12">
                    <div>
                      <div className="md:grid md:grid-cols-1 ">
                        <div className="mt-5 md:mt-0 md:col-span-4">
                          <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                              <h2 className="mt-2 text-left text-1xl font-bold text-green-800">
                                Home bearbeiten
                              </h2>
                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Vereinsname
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="60"
                                      maxLength="60"
                                      value={about.club_name}
                                      onChange={(e) =>
                                        changeAbout({
                                          club_name: e.target.value,
                                        })
                                      }
                                      name="club_name"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="club name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Beschreibung Linke Seite
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <textarea
                                      type="text"
                                      required
                                      value={about.content1}
                                      onChange={(e) =>
                                        changeAbout({
                                          content1: e.target.value,
                                        })
                                      }
                                      size="800"
                                      maxLength="800"
                                      name="content1"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Beschreibung Linke Seite"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Beschreibung Rechte Seite
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <textarea
                                      type="text"
                                      required
                                      value={about.content2}
                                      onChange={(e) =>
                                        changeAbout({
                                          content2: e.target.value,
                                        })
                                      }
                                      size="800"
                                      maxLength="800"
                                      name="content2"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Beschreibung Rechte Seite"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Zitat
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <textarea
                                      type="text"
                                      required
                                      value={about.description}
                                      onChange={(e) =>
                                        changeAbout({
                                          description: e.target.value,
                                        })
                                      }
                                      size="800"
                                      maxLength="800"
                                      name="description"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Zitat"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Zitiert von
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      value={about.quotedby}
                                      onChange={(e) =>
                                        changeAbout({
                                          quotedby: e.target.value,
                                        })
                                      }
                                      size="800"
                                      maxLength="800"
                                      name="quotedby"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Zitiert von"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Logo
                                  </div>
                                  {/* </div> */}
                                </div>

                                <div className="col-span-4 sm:col-span-4">
                                  <img
                                    src={about.logo}
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      height: "200px",
                                      width: "100%",
                                    }}
                                  />
                                  <div className="mt-1 flex rounded-md">
                                    <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                      <div className="absolute">
                                        <div className="flex flex-col items-center ">
                                          {" "}
                                          <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            Hängen Sie hier Ihre Dateien an
                                          </span>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            oder
                                          </span>{" "}
                                          <span className="block text-blue-400 font-normal">
                                            Dateien durchsuchen
                                          </span>{" "}
                                        </div>
                                      </div>{" "}
                                      <input
                                        type="file"
                                        className="h-full w-full opacity-0"
                                        name=""
                                        accept="image/*"
                                        multiple
                                        onChange={(e) => {
                                          setSelectedFile(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                    <div className="flex justify-between items-center text-gray-400">
                                      {" "}
                                      <span>
                                        Akzeptierte Bilder nur jpeg/png
                                      </span>{" "}
                                      <span className="flex items-center ">
                                        <i className="fa fa-lock mr-1"></i>{" "}
                                        secure
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Hintergrundbild
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <img
                                    src={about.cover}
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      height: "200px",
                                      width: "100%",
                                    }}
                                  />
                                  <div className="mt-1 flex rounded-md">
                                    <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                      <div className="absolute">
                                        <div className="flex flex-col items-center ">
                                          {" "}
                                          <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            Hängen Sie hier Ihre Dateien an
                                          </span>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            oder
                                          </span>{" "}
                                          <span className="block text-blue-400 font-normal">
                                            Dateien durchsuchen
                                          </span>{" "}
                                        </div>
                                      </div>{" "}
                                      <input
                                        type="file"
                                        className="h-full w-full opacity-0"
                                        name=""
                                        accept="image/*"
                                        multiple
                                        onChange={(e) => {
                                          setCoverFile(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                    <div className="flex justify-between items-center text-gray-400">
                                      {" "}
                                      <span>
                                        Akzeptierte Bilder nur jpeg/png
                                      </span>{" "}
                                      <span className="flex items-center ">
                                        <i className="fa fa-lock mr-1"></i>{" "}
                                        secure
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Home-Bild
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <img
                                    src={about.about_image}
                                    alt=""
                                    style={{
                                      objectFit: "cover",
                                      height: "200px",
                                      width: "100%",
                                    }}
                                  />
                                  <div className="mt-1 flex rounded-md">
                                    <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                      <div className="absolute">
                                        <div className="flex flex-col items-center ">
                                          {" "}
                                          <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            Hängen Sie hier Ihre Dateien an
                                          </span>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            oder
                                          </span>{" "}
                                          <span className="block text-blue-400 font-normal">
                                            Dateien durchsuchen
                                          </span>{" "}
                                        </div>
                                      </div>{" "}
                                      <input
                                        type="file"
                                        className="h-full w-full opacity-0"
                                        name=""
                                        accept="image/*"
                                        multiple
                                        onChange={(e) => {
                                          setAboutFile(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                    <div className="flex justify-between items-center text-gray-400">
                                      {" "}
                                      <span>
                                        Akzeptierte Bilder nur jpeg/png
                                      </span>{" "}
                                      <span className="flex items-center ">
                                        <i className="fa fa-lock mr-1"></i>{" "}
                                        secure
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Telefonnummer
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="60"
                                      value={about.phone}
                                      onChange={(e) =>
                                        changeAbout({ phone: e.target.value })
                                      }
                                      maxLength="60"
                                      name="phone"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="phone"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    E-Mail
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="60"
                                      value={about.email}
                                      onChange={(e) =>
                                        changeAbout({ email: e.target.value })
                                      }
                                      maxLength="60"
                                      name="email"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Email"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Adresse
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="90"
                                      value={about.address}
                                      onChange={(e) =>
                                        changeAbout({
                                          address: e.target.value,
                                        })
                                      }
                                      maxLength="90"
                                      name="address"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Strasse Name und Nummer"
                                    />
                                  </div>

                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="90"
                                      value={about.postalcode_city}
                                      onChange={(e) =>
                                        changeAbout({
                                          postalcode_city: e.target.value,
                                        })
                                      }
                                      maxLength="90"
                                      name="city"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Postleizahl und Stadt"
                                    />
                                  </div>
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="90"
                                      value={about.operator}
                                      onChange={(e) =>
                                        changeAbout({
                                          operator: e.target.value,
                                        })
                                      }
                                      maxLength="90"
                                      name="operator"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Betreiber"
                                    />
                                  </div>
                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                      type="text"
                                      required
                                      size="90"
                                      value={about.company_name}
                                      onChange={(e) =>
                                        changeAbout({
                                          company_name: e.target.value,
                                        })
                                      }
                                      maxLength="90"
                                      name="company_name"
                                      className="focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Firmaname"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Impressum
                                  </div>
                                  {/* </div> */}
                                </div>
                                <div className="col-span-4 sm:col-span-4">
                                  <div className="flex space-x-4">
                                    <button
                                      onClick={handleLineBreak}
                                      className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                                    >
                                      Linie brechen
                                    </button>
                                    <button
                                      onClick={handleParagraph}
                                      className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                                    >
                                      Absatz
                                    </button>
                                    <button
                                      onClick={handleBold}
                                      className="flex items-center justify-center px-4 py-2 border border-gray-200 rounded-md shadow-sm text-sm font-regular text-gray-500 bg-white hover:bg-gray-600 hover:text-white"
                                    >
                                      Fett
                                    </button>
                                  </div>

                                  <div className="mt-1 flex rounded-md shadow-sm">
                                    <textarea
                                      onChange={onChangeContent}
                                      value={about.terms}
                                      rows="20"
                                      className="resize-y rounded-md focus:ring-green-500 focus:border-green-500 flex-1 rounded-l-md border  block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                      placeholder="Bitte geben Sie hier Ihren Nachrichteninhalt ein"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-0 ">
                                <div className="col-span-2 mt-3 sm:col-span-2 justify-center">
                                  {/* <div className="mt-3 flex rounded-md shadow-sm"> */}
                                  <div className=" text-sm font-medium text-gray-700">
                                    Datenschutzbestimmungen
                                  </div>
                                  {/* </div> */}
                                </div>

                                <div className="col-span-4 sm:col-span-4">
                                  <embed
                                    alt="news image"
                                    src={about.data_privacy}
                                    style={{
                                      objectFit: "cover",
                                      height: "200px",
                                      width: "100%",
                                    }}
                                  />
                                  <div className="mt-1 flex rounded-md">
                                    <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                      <div className="absolute">
                                        <div className="flex flex-col items-center ">
                                          {" "}
                                          <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            Hängen Sie hier Ihre Dateien an
                                          </span>{" "}
                                          <span className="block text-gray-400 font-normal">
                                            oder
                                          </span>{" "}
                                          <span className="block text-blue-400 font-normal">
                                            Dateien durchsuchen
                                          </span>{" "}
                                        </div>
                                      </div>{" "}
                                      <input
                                        type="file"
                                        className="h-full w-full opacity-0"
                                        name=""
                                        accept="application/*"
                                        multiple
                                        onChange={(e) => {
                                          setPrivacyFile(e.target.files[0]);
                                        }}
                                      />
                                    </div>
                                    <div className="flex justify-between items-center text-gray-400">
                                      {" "}
                                      <span>
                                        Akzeptierte Bilder nur pdf/docx
                                      </span>{" "}
                                      <span className="flex items-center ">
                                        <i className="fa fa-lock mr-1"></i>{" "}
                                        secure
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}

            {active === "members" ? (
              <div>
                <div className="bg-white">
                  <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex space-x-4 items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center"></div>

                      <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button
                          onClick={(e) => setMemberOpen(true)}
                          className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                        >
                          Hinzufügen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-2 px- sm:px-6 lg:px-8">
                  <div className="max-w-xl w-full space-y-12">
                    <main className="w-full max-w-8xl mx-auto font-lexend">
                      <div className="text-left">
                        <h1 className="text-4xl font-bold">Vorstand</h1>
                      </div>
                      <br />
                      <div className="py-4">
                        <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {about.board_members.map((m, i) => (
                              <div
                                key={i}
                                className="w-full bg-gray-100 rounded-lg p-12 flex flex-col justify-center items-center"
                              >
                                <div className="mb-8">
                                  <img
                                    className="object-center object-cover rounded-full h-36 w-36"
                                    src={m.picture}
                                    alt="photo"
                                  />
                                </div>
                                <div className="text-center">
                                  <p className="text-2xl text-gray-700 font-bold mb-2">
                                    {m.name}
                                  </p>
                                  <p className="text-base text-gray-500 font-normal">
                                    {m.role}
                                  </p>
                                  <u>
                                    <p className="text-base text-gray-500 font-normal mb-2">
                                      {m.email ? m.email : ""}
                                    </p>
                                  </u>
                                  <p className="text-base text-gray-500 font-normal">
                                    {m.phone ? m.phone : ""}
                                  </p>
                                </div>
                                <div className="p-4">
                                  <Button
                                    onClick={(e) => removeItem(m._id, 0)}
                                    appearance="primary"
                                  >
                                    Löschen
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {active === "othermembers" ? (
              <div>
                <div className="bg-white">
                  <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex space-x-4 items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center"></div>

                      <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button
                          onClick={(e) => setExtendedOpen(true)}
                          className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                        >
                          Hinzufügen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-2 px- sm:px-6 lg:px-8">
                  <div className="max-w-xl w-full space-y-12">
                    <main className="w-full max-w-8xl mx-auto font-lexend">
                      <div className="text-left">
                        <h1 className="text-4xl font-bold">
                          Erweiterter Vorstand
                        </h1>
                      </div>
                      <br />
                      <div className="py-4">
                        <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {about.extended_members.map((m, i) => (
                              <div
                                key={i}
                                className="w-full bg-gray-100 rounded-lg p-12 flex flex-col justify-center items-center"
                              >
                                <div className="mb-8">
                                  <img
                                    className="object-center object-cover rounded-full h-36 w-36"
                                    src={m.picture}
                                    alt="photo"
                                  />
                                </div>
                                <div className="text-center">
                                  <p className="text-2xl text-gray-700 font-bold mb-2">
                                    {m.name}
                                  </p>
                                  <p className="text-base text-gray-500 font-normal">
                                    {m.role}
                                  </p>
                                  <u>
                                    <p className="text-base text-gray-500 font-normal mb-2">
                                      {m.email ? m.email : ""}
                                    </p>
                                  </u>
                                  <p className="text-base text-gray-500 font-normal">
                                    {m.phone ? m.phone : ""}
                                  </p>
                                </div>
                                <div className="p-4">
                                  <Button
                                    onClick={(e) => removeItem(m._id, 3)}
                                    appearance="primary"
                                  >
                                    Löschen
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {active === "timelines" ? (
              <div>
                <div className="bg-white">
                  <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex space-x-4 items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center"></div>

                      <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button
                          onClick={(e) => setTimelineOpen(true)}
                          className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                        >
                          Hinzufügen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-2 px- sm:px-6 lg:px-8">
                  <div className="max-w-xl w-full space-y-12">
                    <main className="w-full max-w-8xl mx-auto font-lexend">
                      <div className="text-left">
                        <h1 className="text-4xl font-bold">Chronik</h1>
                      </div>
                      <br />
                      <div className="py-4">
                        <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
                          <div className="grid grid-cols-1 gap-6">
                            {about.timelines.map((m, i) => (
                              <div
                                key={i}
                                className="w-full bg-gray-100 rounded-lg p-6 flex flex-col justify-center items-center"
                              >
                                <div className="text-center">
                                  <p className="text-2xl text-gray-700 font-bold mb-2">
                                    {m.year}
                                  </p>
                                  <p className="text-base text-gray-500 font-normal">
                                    {m.description}
                                  </p>
                                </div>
                                <div className="p-4">
                                  <Button
                                    onClick={(e) => removeItem(m._id, 1)}
                                    appearance="primary"
                                  >
                                    Löschen
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {active === "downloads" ? (
              <div>
                <div className="bg-white">
                  <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div className="flex space-x-4 items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center"></div>

                      <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button
                          onClick={(e) => setDownloadOpen(true)}
                          className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600"
                        >
                          Hinzufügen
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full min-h-full flex items-center justify-center bg-gray-50 py-2 px- sm:px-6 lg:px-8">
                  <div className="max-w-xl w-full space-y-12">
                    <main className="w-full max-w-8xl mx-auto font-lexend">
                      <div className="text-left">
                        <h1 className="text-4xl font-bold">Downloads</h1>
                      </div>
                      <br />
                      <div className="py-4">
                        <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {about.downloads?.map((m, i) => (
                              <div
                                key={i}
                                className="w-full bg-gray-100 rounded-lg p-0 flex flex-col justify-center items-center"
                              >
                                <div className="mb-8">
                                  <embed
                                    alt="news image"
                                    src={m.document}
                                    style={{
                                      objectFit: "cover",
                                      height: "auto",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                                <div className="text-center">
                                  <p className="text-2xl text-gray-700 font-bold mb-2">
                                    {m.name}
                                  </p>
                                </div>
                                <div className="p-4">
                                  <Button
                                    onClick={(e) => removeItem(m._id, 2)}
                                    appearance="primary"
                                  >
                                    Löschen
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </section>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <MemberModal
            open={member_open}
            id={about._id}
            handleClose={() => setMemberOpen(false)}
            handleOk={() => {
              loadAbout();
              setMemberOpen(false);
            }}
          />
          <ExtendedMemberModal
            open={extended_open}
            id={about._id}
            handleClose={() => setExtendedOpen(false)}
            handleOk={() => {
              loadAbout();
              setExtendedOpen(false);
            }}
          />
          <TimelineModal
            open={timeline_open}
            id={about._id}
            handleClose={() => setTimelineOpen(false)}
            handleOk={() => {
              loadAbout();
              setTimelineOpen(false);
            }}
          />
          <DownloadModal
            open={download_open}
            id={about._id}
            handleClose={() => setDownloadOpen(false)}
            handleOk={() => {
              loadAbout();
              setDownloadOpen(false);
            }}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Create;
