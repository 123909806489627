import React, { useEffect, useState, useContext } from "react";
import { Fragment, useRef } from "react";

import Sidebar from "../../containers/sidebar";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";

import {
  HiDotsHorizontal,
  HiExclamation,
  HiOutlineTrash,
  HiDuplicate,
  HiEye,
  HiPencil,
} from "react-icons/hi";

import { AuthContext } from "../../contexts/AuthContext";
import { UserContext } from "../../contexts/UserContext";
import Call from "../../util/Call";
import dayjs from "dayjs";

import Navbar from "../../containers/navbar";
import { Link } from "react-router-dom";
import Table from "./table";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

dayjs().format();

let limit = 20;
function NewsManagement() {
  const { user } = useContext(AuthContext);
  const [showView, setShowView] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [premises, setPremises] = useState([]);
  const [start, setStart] = useState(0);
  const [total, setTotal] = useState(0);

  const fetchNews = (s) => {
    setLoading(true);
    Call({
      path: "home/premises/",
      method: "get",
      params: { start: s, limit },
    })
      .then((d) => {
        setPremises(d.data);
        setTotal(d.total);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const handleDelete = () => {
    setOpen(true);
    setId(id);
  };
  const removeNews = () => {};

  const handleRemoveNews = () => {
    removeNews(id);
  };

  useEffect(() => {
    fetchNews(0);
  }, []);

  const handleVisibility = () => {
    setShowView(true);
  };

  const changePage = (page) => {
    let s = page * limit - limit;
    fetchNews(s);
  };
  return (
    <>
      <Sidebar />
      <Navbar />
      {user ? (
        <>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 xl:max-w-5xl xl:px-0  flex">
            <main className="w-full max-w-8xl mx-auto font-lexend">
              <div className="bg-white">
                <div className="max-w-7xl content-center mx-auto py-3 px-3 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                      {/* <span className="flex p-2 rounded-lg bg-indigo-800"></span> */}
                    </div>
                    {user.role === "Superadministrator" ? (
                      <Link to="/create-premises">
                        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                          <button className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600">
                            Neue Anlage
                          </button>
                        </div>
                      </Link>
                    ) : (
                      <div className="text-sm">
                        ‘Edit’, ’Delete’, ’New Admin’ functions are only
                        available for ’Super’ Admin. &nbsp;
                        <a href="/" className="text-green-500">
                          <u>See More</u>
                        </a>
                        <HiExclamation className="w-5 h-5 text-green-500" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="flex flex-col">
                <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="pl-60	 align-middle block sm:pl-60 lg:pl-60">
                    <div className="shadow border-b border-gray-200">
                      <Table
                        data={premises}
                        loading={loading}
                        limit={2}
                        total={total}
                        changePage={changePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
export default NewsManagement;
