import axios from "axios";

export const authHeader = () => {
  let storageItem = JSON.parse(localStorage.getItem("user"));
  if (!storageItem) {
    return { Authorization: "" };
  } else {
    let token = storageItem.data.token;
    return { Authorization: `Bearer ${token}` };
  }
};
