import React, { createContext, useReducer } from "react";
import authReducer from "../reducers/user";
import ACTION from "../actions/type";
import Service from "../services/user.service";

const initialState = {
  admins: [],
  user_pagination: {
    total: 0,
    limit: 10,
    start: 0,
  },
  loading: false,
};

export const UserContext = createContext(initialState);

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function fetchAdmins(query) {
    return new Promise((resolve, reject) => {
      Service.fetchAdminData(query)
        .then((body) => {
          dispatch({ type: ACTION.USER_ADMIN, body });
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function removeAdmin(data) {
    return new Promise((resolve, reject) => {
      Service.removeAdmin(data)
        .then((body) => {
          dispatch({ type: ACTION.REMOVE_ADMIN, body });
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  function create(data) {
    return new Promise((resolve, reject) => {
      Service.create(data)
        .then((body) => {
          dispatch({ type: ACTION.USER_CREATE, body });
          resolve(body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  return (
    <UserContext.Provider
      value={{
        loading: state.loading,
        admins: state.admins,
        removeAdmin,
        fetchAdmins,
        create,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
