import React from "react";
import {
  HiOutlineSearch,
  HiTicket,
  HiX,
  HiViewBoards,
  HiUser,
  HiUserCircle,
  HiMail,
  HiNewspaper,
  HiOutlineHome,
  HiArchive,
  HiOutlineUsers,
  HiMenuAlt4,
  HiCash,
  HiPresentationChartBar,
  HiKey,
  HiBookOpen,
  HiLocationMarker,
  HiFolderOpen,
  HiAcademicCap,
  HiOutlineUserGroup,
  HiTemplate,
  HiHome,
  HiCalendar,
  HiInformationCircle,
} from "react-icons/hi";
import {
  MdAccessibility,
  MdBuild,
  MdImage,
  MdRestaurant,
  MdRestaurantMenu,
  MdSettings,
} from "react-icons/md";
import { AuthContext } from "../../contexts/AuthContext";

// import { links } from "./data";
// import { useGlobalContext } from "./context";

const Sidebar = () => {
  const { user, about, restaurant, premises, loadAbout } =
    React.useContext(AuthContext);

  return (
    <div
      style={{ zIndex: 10 }}
      className="transition-all  duration-500  fixed top-0"
    >
      <div className="flex h-screen overflow-y-auto flex-col bg-green-500  w-64 px-4 py-8 border-r min-h-screen relative">
        {/* <button className="absolute top-1 right-1  text-green-600 w-8 h-8 rounded-full flex items-center justify-center active:bg-green-300 focus:outline-none ml-6 hover:bg-green-200 hover:text-green-800"></button> */}
        <h2 className="text-3xl font-semibold text-gray-800">Garden</h2>
        <div className="flex flex-col mt-2  justify-between flex-1">
          <nav className="text-xs">
            {about ? (
              <a
                href="/homepage-inhalt"
                className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
              >
                <HiMenuAlt4 className="w-5 h-5" />
                <span className="mx-4 font-medium">Homepage-Inhalt</span>
              </a>
            ) : (
              ""
            )}

            {about ? (
              <a
                href="/about-us"
                className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
              >
                <HiInformationCircle className="w-5 h-5" />
                <span className="mx-4 font-medium">Home</span>
              </a>
            ) : (
              <a
                href="/create-about"
                className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
              >
                <HiMenuAlt4 className="w-5 h-5" />
                <span className="mx-4 font-medium">Home</span>
              </a>
            )}

            {/* <a
              href="/membership-management"
              className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
            >
              <HiOutlineUsers className="w-5 h-5" />
              <span className="mx-4 font-medium">Mitgliedschaft</span>
            </a> */}
            <a
              href="/news-management"
              className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
            >
              <HiNewspaper className="w-5 h-5" />
              <span className="mx-4 font-medium">Nachrichten</span>
            </a>

            <a
              href={restaurant ? "/restaurant" : "create-restaurant"}
              className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
            >
              <HiHome className="w-5 h-5" />
              <span className="mx-4 font-medium">Vereinsheim</span>
            </a>

            <a
              href={"/premises"}
              className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
            >
              <MdAccessibility className="w-5 h-5" />
              <span className="mx-4 font-medium">Anlage</span>
            </a>

            <a
              href={"/termine"}
              className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
            >
              <HiCalendar className="w-5 h-5" />
              <span className="mx-4 font-medium">Termine</span>
            </a>

            <a
              href={"/gallery"}
              className="flex items-center px-4 py-2 mt-5 rounded-md text-white hover:text-white hover:bg-green-600 transition-colors transform"
            >
              <MdImage className="w-5 h-5" />
              <span className="mx-4 font-medium">Galerie</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
