import Axios from "axios";
import { NotificationManager } from "react-notifications";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/`;

export default function call({ path, method, data, params }) {
  return new Promise((resolve, reject) => {
    Axios({
      url: API_URL + path,
      method,
      headers: {
        authorization: "Bearer " + window.localStorage.getItem("token") || "",
      },
      params,
      data,
    })
      .then((d) => resolve(d.data))
      .catch((err) => {
        let e = err?.response?.data;
        if (!e) return;
        switch (e.type) {
          case "array":
            let i = 0;
            for (let m of e.body) {
              NotificationManager.error(m, "", 3000 - i);
              i++;
            }
            break;

          default:
            NotificationManager.error(e.body, "", 3000);
            break;
        }
        reject(e);
      });
  });
}
